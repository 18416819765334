<script setup lang="ts">
import { tv } from "tailwind-variants";

const variants = tv({
  variants: {
    size: {
      xs: "text-xs",
      small: "text-sm",
      medium: "text-base",
      large: "text-lg",
      heading: "text-2xl",
    },
    bold: {
      true: "font-medium",
    },
    color: {
      black: "text-black",
      gray: "text-gray-500",
    },
  },
  defaultVariants: {
    size: "medium",
  },
});

const props = defineProps<{
  translationKey?: string;
  classes?: string;
  size?: "xs" | "small" | "medium" | "large" | "heading";
  color?: "black" | "gray";
  bold?: boolean;
}>();

const { t } = useNuxtApp().$i18n;
const variantClasses = computed(() =>
  variants({ size: props.size, bold: props.bold, color: props.color, class: props.classes }),
);
const translation = computed(() => (props.translationKey ? t(props.translationKey) : undefined));
</script>

<template>
  <span :class="variantClasses">
    <text v-if="translation" v-html="translation"></text>
    <slot v-else></slot>
  </span>
</template>
